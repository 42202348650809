export interface Career {
  title: string;
  position: string;
  timeIn: string;
  stackUsed: string;
}

export const Careers: Career[] = [
  {
    title: "Hospital Israelita Albert Einstein",
    position: "Tech Lead",
    timeIn: "12/2022 - Present",
    stackUsed:
      "NodeJS, ReactJS, ReactNative, PostgreSQL, MySQL, MongoDb, NextJS,\n" +
      "               MaterialUI",
  },
  {
    title: "ACT Digital",
    position: "Tech Lead",
    timeIn: "12/2022 - Present",
    stackUsed:
      "NodeJS, ReactJS, ReactNative, PostgreSQL, MySQL, MongoDb, NextJS,\n" +
      "               MaterialUI",
  },
  {
    title: "Red Fox Soluções Digitais",
    position: "Tech Lead",
    timeIn: "08/2020 - Present",
    stackUsed:
      "NodeJS, ReactJS, ReactNative, PostgreSQL, MySQL, MongoDb, NextJS,\n" +
      "              FeathersJs, MaterialUI, Swift, Java",
  },
  {
    title: "GTC",
    position: "Full Stack Developer",
    timeIn: "11/2019 - 08/2020",
    stackUsed: "PHP, Laravel, MySQL, AWS, JQuery, CSS, Javascript, HTML",
  },
];
