import React from "react";
import { CostumersList } from "../../shared/constants/costumers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { Container, styled, useMediaQuery, useTheme } from "@mui/material";
import { Section, SectionProps } from "../../shared/components";

interface CostumersProps {
  sectionProps?: SectionProps;
}

const SSwiperSlide = styled(SwiperSlide)`
  width: 250px;
  height: 250px;
  display: flex;
  align-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.1);
  }
`;

export function CostumersCarrousel({ sectionProps }: CostumersProps) {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(() => breakpoints.down("md"));
  return (
    <Section {...sectionProps}>
      <Container
        sx={{ height: 450, display: "flex", alignItems: "center" }}
        id={"work"}
      >
        <Swiper
          spaceBetween={isMobile ? 25 : 150}
          slidesPerView={isMobile ? 2 : 4}
          slidesPerGroup={isMobile ? 2 : 4}
          autoplay={{
            delay: isMobile ? 1500 : 3500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          loop
          navigation
          loopFillGroupWithBlank
        >
          {CostumersList.map((costumer, index) => (
            <SSwiperSlide
              key={index}
              id={`slider_icon_${costumer.name}`}
              data-testid={`slider_icon_${costumer.name}`}
            >
              {costumer.icon}
            </SSwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Section>
  );
}
