import React from "react";
import {
  AppBar,
  Container,
  Grid,
  IconButton,
  Link,
  useTheme,
} from "@mui/material";
import { ICON_DEFAULT_SIZE, SocialMediasProps } from "../../constants";

interface HeaderProps {
  socialMedias: SocialMediasProps[];
}

export function Header({ socialMedias }: HeaderProps) {
  const { palette } = useTheme();
  return (
    <Container>
      <AppBar
        position={"static"}
        elevation={0}
        sx={{ background: palette.background.default, height: 80, px: 2 }}
      >
        <Grid container direction={"row"} height={"100%"}>
          <Grid
            container
            item
            md={6}
            spacing={2}
            height={100}
            alignItems={"center"}
          >
            <Grid item>
              <Link
                color={"white"}
                href={"#work"}
                underline={"none"}
                data-testid={"header_work_link"}
              >
                Work
              </Link>
            </Grid>
            <Grid item>
              <Link
                color={"white"}
                href={"#about-me"}
                underline={"none"}
                data-testid={"header_aboutMe_link"}
              >
                About me
              </Link>
            </Grid>
            <Grid item>
              <Link
                color={"white"}
                href={"mailto:contact@kennedyribeiro.dev?subject = Contact"}
                underline={"none"}
                data-testid={"header_contactMe_link"}
              >
                Contact me
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={6}
            direction={"row"}
            justifyContent={"flex-end"}
            height={"100%"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item>
              {socialMedias.map((socialMedia, index) => (
                <IconButton
                  key={index}
                  sx={{ fontSize: ICON_DEFAULT_SIZE }}
                  onClick={() => window.open(socialMedia.link, "_blank")}
                >
                  {socialMedia.icon}
                </IconButton>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </Container>
  );
}
