import React from "react";
import { ABOUT_ME, SocialMedias } from "../../shared/constants";
import { Button, Container, Grid } from "@mui/material";
import { ProfilePicture } from "../../shared/components";

export function AllMyLinksScreen() {
  return (
    <Container maxWidth={"xs"}>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          minHeight: "100vh",
        }}
        gap={1}
      >
        <Grid item>
          <ProfilePicture
            profilePicturePath={ABOUT_ME.profilePicture}
            height={200}
          />
        </Grid>

        {SocialMedias.map((social) => (
          <Grid
            item
            key={`social-medias-icon-${social.name}`}
            sx={{ width: "100%" }}
          >
            <Button
              component={"a"}
              href={social.link}
              target={"noopener"}
              fullWidth
              role={"button"}
              sx={{
                background: "#333",
                color: "#fff",
                height: 50,
                borderRadius: "5px",
                gap: 1,
                textTransform: "inherit",
                transition: "all 0.2s ease-in-out",
                display: "flex",
                "&:hover, &:focus": {
                  background: "#29505e",
                  transform: "scale(1.03)",
                  color: "#5197bd",
                },
              }}
            >
              <Grid
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  transform: "scale(1.4)",
                }}
              >
                {social.icon}
              </Grid>
              <Grid>{social.name}</Grid>
            </Button>
          </Grid>
        ))}
        <Grid item>
          <Button component={"a"} href={"/"}>
            <img
              src={"images/trademark_white.png"}
              alt={"brand logo"}
              style={{ width: 80 }}
            />
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
