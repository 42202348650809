import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111818",
      paper: "#111818",
    },
    primary: {
      main: "#5a89aa",
    },
    secondary: {
      main: "#ffa726",
    },
  },
});
