export interface ProjectListProps {
  name: string;
  desc: string;
  about: string;
  technologies: string[];
  images: string[];
  link: string;
}

export const ProjectsList: ProjectListProps[] = [
  {
    name: "Fisk",
    desc: "A Website for a english school ",
    about:
      "Website created for the Fisk English School, using Laravel this website is for all the institution and they affiliates, includes remote classess ",
    technologies: ["Laravel", "MySql"],
    images: [
      "/images/clients/fisk/01.png",
      "/images/clients/fisk/02.png",
      "/images/clients/fisk/03.png",
      "/images/clients/fisk/04.png",
      "/images/clients/fisk/05.png",
      "/images/clients/fisk/06.png",
      "/images/clients/fisk/07.png",
      "/images/clients/fisk/08.png",
      "/images/clients/fisk/09.png",
      "/images/clients/fisk/10.png",
      "/images/clients/fisk/11.png",
    ],
    link: "https://www.fisk.com.br",
  },
  {
    name: "Meu Mundo Nutri",
    desc: "Importance of child nutrition",
    about:
      "Website for a food company that wants to warn about the importance of good nutrition during childhood, with games, videos and reports for parents",
    technologies: [
      "PHP",
      "Wordpress",
      "CSS",
      "Bootstrap",
      "Javascript",
      "Jquery",
      "MySql",
    ],
    images: [
      "/images/clients/meumundonutri/01.png",
      "/images/clients/meumundonutri/02.png",
      "/images/clients/meumundonutri/03.png",
      "/images/clients/meumundonutri/04.png",
      "/images/clients/meumundonutri/05.png",
      "/images/clients/meumundonutri/06.png",
      "/images/clients/meumundonutri/07.png",
      "/images/clients/meumundonutri/08.png",
      "/images/clients/meumundonutri/09.png",
      "/images/clients/meumundonutri/10.png",
      "/images/clients/meumundonutri/11.png",
      "/images/clients/meumundonutri/13.png",
      "/images/clients/meumundonutri/14.png",
      "/images/clients/meumundonutri/15.png",
      "/images/clients/meumundonutri/16.png",
      "/images/clients/meumundonutri/17.png",
      "/images/clients/meumundonutri/18.png",
      "/images/clients/meumundonutri/19.png",
    ],
    link: "https://meumundonutri.com.br/",
  },
  {
    name: "GIV",
    desc: "Graphic shop online",
    about:
      "A print E-commerce created with Laravel in frontend and an API RestFul to comport multiple e-commerces and apps.",
    technologies: [
      "PHP",
      "Laravel",
      "CSS",
      "Bootstrap",
      "Javascript",
      "Jquery",
      "MySql",
      "Amazon Web Services - S3 ",
    ],
    images: [
      "/images/clients/giv/01.png",
      "/images/clients/giv/02.png",
      "/images/clients/giv/03.png",
      "/images/clients/giv/04.png",
      "/images/clients/giv/05.png",
      "/images/clients/giv/06.png",
      "/images/clients/giv/07.png",
      "/images/clients/giv/08.png",
      "/images/clients/giv/09.png",
      "/images/clients/giv/10.png",
      "/images/clients/giv/11.png",
      "/images/clients/giv/12.png",
      "/images/clients/giv/13.png",
      "/images/clients/giv/14.png",
      "/images/clients/giv/15.png",
      "/images/clients/giv/16.png",
      "/images/clients/giv/17.png",
      "/images/clients/giv/18.png",
      "/images/clients/giv/19.png",
      "/images/clients/giv/20.png",
      "/images/clients/giv/21.png",
      "/images/clients/giv/22.png",
    ],
    link: "https://givonline.com.br/",
  },
  {
    name: "FuturaIM",
    desc: "Graphic shop online",
    about:
      "A print E-commerce created with Laravel in frontend and an API RestFul to comport multiple e-commerces and apps.",
    technologies: [
      "PHP",
      "Laravel",
      "CSS",
      "Bootstrap",
      "Javascript",
      "Jquery",
      "MySql",
      "Amazon Web Services - S3 ",
    ],
    images: [
      "/images/clients/futuraim/01.png",
      "/images/clients/futuraim/02.png",
      "/images/clients/futuraim/03.png",
      "/images/clients/futuraim/04.png",
      "/images/clients/futuraim/05.png",
      "/images/clients/futuraim/06.png",
      "/images/clients/futuraim/07.png",
      "/images/clients/futuraim/08.png",
      "/images/clients/futuraim/09.png",
      "/images/clients/futuraim/10.png",
      "/images/clients/futuraim/11.png",
      "/images/clients/futuraim/12.png",
      "/images/clients/futuraim/13.png",
      "/images/clients/futuraim/14.png",
      "/images/clients/futuraim/15.png",
      "/images/clients/futuraim/16.png",
      "/images/clients/futuraim/17.png",
      "/images/clients/futuraim/18.png",
      "/images/clients/futuraim/19.png",
      "/images/clients/futuraim/20.png",
      "/images/clients/futuraim/21.png",
    ],
    link: "https://www.futuraim.com.br/",
  },
  {
    name: "Althaia",
    desc: "Vitamin D",
    about: "Landing page and Blog for a pharmaceutical company ",
    technologies: [
      "PHP",
      "Laravel",
      "CSS",
      "Bootstrap",
      "Javascript",
      "Jquery",
      "MySql",
      "Amazon Web Services - S3 ",
    ],
    images: [
      "/images/clients/althaiavitamina/01.png",
      "/images/clients/althaiavitamina/02.png",
      "/images/clients/althaiavitamina/03.png",
      "/images/clients/althaiavitamina/04.png",
      "/images/clients/althaiavitamina/05.png",
      "/images/clients/althaiavitamina/06.png",
      "/images/clients/althaiavitamina/07.png",
      "/images/clients/althaiavitamina/08.png",
      "/images/clients/althaiavitamina/09.png",
      "/images/clients/althaiavitamina/10.png",
    ],
    link: "https://vitaminadalthaia.com.br/",
  },
];
