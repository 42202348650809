import React from "react";
import { Parallax } from "react-parallax";

interface ParallaxBackgroundProps {
  bgImage: string;
  children: React.ReactNode | React.ReactElement;
}
//
export function ParallaxBackground({
  bgImage,
  children,
}: ParallaxBackgroundProps) {
  // TODO Add a breakpoints to change sizes
  return (
    <>
      <Parallax
        bgImage={bgImage}
        bgImageStyle={{
          width: "100vw",
          height: "auto",
        }}
        blur={{ min: -30, max: 15 }}
      >
        <div style={{ height: "calc(100vh - 80px)" }}>{children}</div>
      </Parallax>
    </>
  );
}
