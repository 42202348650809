import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { SocialMediasProps } from "../../constants";
import { FooterItem } from "./footer-items/footer-item";

interface FooterProps {
  socialMedias: SocialMediasProps[];
}

export function Footer({ socialMedias }: FooterProps) {
  return (
    <>
      <footer data-testid={"footer"}>
        <Box
          sx={{
            bottom: 0,
            minHeight: 160,
            display: "flex",
            alignItems: "center",
            py: 4,
          }}
        >
          <Container>
            <Grid container>
              <Grid item md={2}>
                <img
                  src={"images/trademark_white.png"}
                  alt={"brand logo"}
                  style={{ width: 80 }}
                />
              </Grid>
              <Grid container direction={"column"} item md={6}>
                <Typography variant={"body2"} color={"secondary"}>
                  Contacts
                </Typography>
                {socialMedias.map((socialMedia, index) => (
                  <FooterItem {...{ socialMedia }} key={index} />
                ))}

                <Grid item container sx={{ pt: 2 }}>
                  <Typography color={"#fff"}>
                    made with ❤️ @Copyright 2022
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </footer>
    </>
  );
}
