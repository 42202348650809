import React from "react";
import {
  AboutMeFragment,
  CareerFragment,
  CostumersCarrousel,
  ProjectsGallery,
} from "../../modules";
import { ABOUT_ME, SocialMedias } from "../../shared/constants";
import { Stack } from "../../modules/stack";
import { Footer, Header } from "../../shared/components";
import { useTheme } from "@mui/material";
import { MainBannerFragment } from "../../modules/main-banner";

export function HomeScreen() {
  const { palette } = useTheme();
  return (
    <>
      <Header socialMedias={SocialMedias} />
      <MainBannerFragment />
      <AboutMeFragment myDescription={ABOUT_ME} />
      <Stack
        sectionProps={{
          bgcolor: "#152028",
          borderBottom: `1px solid ${palette.warning.main}`,
          paddingBottom: 4,
          paddingTop: 4,
        }}
      />
      <CareerFragment />
      <CostumersCarrousel
        sectionProps={{
          bgcolor: "#152028",
          borderBottom: `1px solid ${palette.warning.main}`,
          borderTop: `1px solid ${palette.warning.main}`,
          title: "Costumers",
        }}
      />
      <ProjectsGallery
        sectionProps={{
          pt: 8,
          borderBottom: `1px solid ${palette.warning.main}`,
          title: "Open projects",
        }}
      />
      <Footer socialMedias={SocialMedias} />
    </>
  );
}
