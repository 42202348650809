import React from "react";
import { ParallaxBackground } from "../../shared/components";
import { Box, Container, Typography } from "@mui/material";

const BG_IMAGE =
  "https://www.freecodecamp.org/news/content/images/size/w2000/2022/04/nikita-kachanovsky-OVbeSXRk_9E-unsplash.jpg";

export function MainBannerFragment() {
  return (
    <ParallaxBackground bgImage={BG_IMAGE}>
      <Container>
        <Box position={"absolute"} sx={{ top: 250 }}>
          <Typography variant={"h2"}>Hi, I&apos;m Kennedy.</Typography>
          <Typography variant={"h6"} pl={1}>
            I&apos;m a full stack developer.
          </Typography>
        </Box>
      </Container>
    </ParallaxBackground>
  );
}
