import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Stacks } from "../../shared/constants";
import { Section, SectionProps } from "../../shared/components";
import { HoverScaleAnimation } from "../../shared/animations";

interface StackProps {
  sectionProps?: SectionProps;
}

export function Stack({ sectionProps }: StackProps) {
  return (
    <Section {...sectionProps}>
      <Container>
        <Grid container spacing={8} justifyContent={"center"}>
          {Stacks.map((stack, index) => (
            <Grid
              item
              key={index}
              sx={{ display: "flex", justifyItems: "center" }}
            >
              <Box
                width={120}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  ...HoverScaleAnimation,
                }}
              >
                <Box>{stack?.icon && stack.icon}</Box>
                <Box>
                  <Typography> {stack.name} </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}
