export interface AboutMeProps {
  description: string;
  profilePicture: string;
}

export const ABOUT_ME: AboutMeProps = {
  description:
    "I'm a web engineer with solid experience in Backend, Frontend,\n" +
    "                  mobile apps, cloud computing and team management.",
  profilePicture:
    "https://media.licdn.com/dms/image/D4D03AQHsGsTGTOw-3w/profile-displayphoto-shrink_800_800/0/1667601128765?e=1687392000&v=beta&t=6ZxLFZGwtPtW7FChIMimDPs6OMdHoiDcPP-onShxU7g",
};
