import React from "react";
import { Section, SectionProps } from "../../shared/components";
import { CareerTimeline } from "../../shared/components/career-timeline";
import { Careers } from "../../shared/constants/career";

interface CareerProps {
  sectionProps?: SectionProps;
}

export function CareerFragment({ sectionProps }: CareerProps) {
  return (
    <Section {...sectionProps}>
      <CareerTimeline careers={Careers} />
    </Section>
  );
}
