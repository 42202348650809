import React from "react";
import {
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { ProjectsList } from "../../shared/constants/project-list";
import { BsInfoCircle } from "react-icons/bs";
import { Section, SectionProps } from "../../shared/components";

interface ProjectsGalleryProps {
  sectionProps?: SectionProps;
}

export function ProjectsGallery({ sectionProps }: ProjectsGalleryProps) {
  return (
    <Section {...sectionProps}>
      <Container sx={{ height: 1000 }}>
        <ImageList
          sx={{
            height: 800,
            overflowX: "hidden",
            overflowY: "scroll",
            scrollbarWidth: "auto",
            scrollbarColor: "#333 #333",
            "::-webkit-scrollbar": {
              width: "8px",
            },
            "::-webkit-scrollbar-track": {
              background: (theme) => theme.palette.background.default,
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#333",
              borderRadius: "14px",
              border: (theme) =>
                `2px solid ${theme.palette.background.default}`,
            },
          }}
          gap={8}
        >
          {ProjectsList.map((project, index) => (
            <ImageListItem key={index}>
              <img src={project.images[0]} alt={project.name} />
              <ImageListItemBar
                title={project.name}
                subtitle={project.about}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${project.name}`}
                    onClick={() => window.open(project.link, "_blank")}
                  >
                    <BsInfoCircle />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </Section>
  );
}
