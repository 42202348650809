import React from "react";
import { Box, useTheme } from "@mui/material";
import { NavigationRoutes } from "./navigation";

function App() {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: palette.background.default,
        height: "100%",
      }}
    >
      <NavigationRoutes />
    </Box>
  );
}

export default App;
