import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { CareerItem } from "./career-item";
import { Career } from "../../constants/career";
import { useMediaQuery, useTheme } from "@mui/material";

interface CareerProps {
  careers: Career[];
}

export function CareerTimeline({ careers }: CareerProps) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(() => breakpoints.down("md"));

  return (
    <Timeline position={isMobile ? "right" : "alternate"}>
      {careers.map((career, key) => (
        <TimelineItem key={key}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <CareerItem {...{ ...career }} index={key} />
        </TimelineItem>
      ))}
    </Timeline>
  );
}
