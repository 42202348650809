import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { TimelineContent } from "@mui/lab";
import React from "react";
import { Career } from "../../../constants/career";

export type CareerItemProps = Career & {
  index: number;
};

export function CareerItem({
  timeIn,
  title,
  stackUsed,
  position,
  index,
}: CareerItemProps) {
  const { palette, breakpoints } = useTheme();

  const isMobile = useMediaQuery(() => breakpoints.down("md"));

  return (
    <TimelineContent
      sx={{
        ...(index % 2 === 1 && !isMobile
          ? { display: "flex", flexDirection: "column", alignItems: "end" }
          : {}),
      }}
    >
      <Typography variant={"h5"} color={"primary"}>
        {title}
      </Typography>
      <Typography color={palette.warning.main} fontSize={16}>
        {position}
      </Typography>
      <Typography variant={"caption"} color={palette.warning.main}>
        {timeIn}
      </Typography>
      <Typography
        variant={"body2"}
        sx={{
          width: 300,

          ...(index % 2 === 1 && !isMobile
            ? { width: 200, textAlign: "end" }
            : {}),
        }}
      >
        {stackUsed}
      </Typography>
    </TimelineContent>
  );
}
