import React from "react";
import { BoxProps, Box, Typography, Container } from "@mui/material";

export type SectionProps = BoxProps & {
  title?: string;
};

export function Section({ sx, title, children, ...props }: SectionProps) {
  return (
    <Box
      sx={{
        minHeight: 500,
        display: "flex",
        flexDirection: "column",
        py: 4,
        ...sx,
      }}
      {...props}
      data-testid={"section"}
    >
      {title && (
        <Container>
          <Typography variant={"h3"} textAlign={"center"} color={"primary"}>
            {title}
          </Typography>
        </Container>
      )}
      {children}
    </Box>
  );
}
