import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { SocialMediasProps } from "../../../constants";

interface FooterItemProps {
  socialMedia: SocialMediasProps;
}

export function FooterItem({ socialMedia }: FooterItemProps) {
  return (
    <Grid item>
      <Link href={socialMedia.link} underline={"none"} sx={{ display: "flex" }}>
        <Box sx={{ fontSize: 18, color: "#fff" }}>{socialMedia.icon}</Box>
        <Typography
          color={"#fff"}
          variant={"caption"}
          fontSize={14}
          sx={{ pl: 1, width: "fit-content" }}
        >
          {socialMedia.name}
        </Typography>
      </Link>
    </Grid>
  );
}
