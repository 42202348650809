import React from "react";
import { FaGithub, FaLinkedin, FaPhone } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

export interface SocialMediasProps {
  link: string;
  icon: React.ReactElement;
  name: string;
}

export const SocialMedias: SocialMediasProps[] = [
  {
    link: "https://www.linkedin.com/in/kennedy-ferreira-da-silva/",
    icon: <FaLinkedin />,
    name: "Kennedy Ribeiro",
  },
  {
    link: "https://github.com/kennedy-f",
    icon: <FaGithub />,
    name: "Kennedy Ribeiro",
  },
  {
    link: "mailto:contact@kennedyribeiro.dev?subject = Contact",
    icon: <FiMail />,
    name: "contact@kennedyribeiro.dev",
  },
  {
    link: "https://wa.me/+5511984025969",
    icon: <FaPhone />,
    name: "+55 (11) 98402-5969",
  },
];
