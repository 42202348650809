import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { ProfilePicture, Section, SectionProps } from "../../shared/components";
import { AboutMeProps } from "../../shared/constants";

interface AboutMeFragmentProps {
  sectionProps?: SectionProps;
  myDescription: AboutMeProps;
}

const IMAGE_HEIGHT = 300;

export function AboutMeFragment({
  sectionProps,
  myDescription,
}: AboutMeFragmentProps) {
  const { palette } = useTheme();

  return (
    <Section
      sx={{
        borderBottom: `1px solid ${palette.warning.main}`,
      }}
      {...sectionProps}
    >
      <Box id={"about-me"}>
        <Container>
          <Grid container direction={"row"} spacing={2} pt={8}>
            <Grid
              item
              container
              md={6}
              xs={12}
              sx={{
                justifyContent: {
                  md: "flex-end",
                  xs: "center",
                },
              }}
            >
              <ProfilePicture
                profilePicturePath={myDescription.profilePicture}
                height={IMAGE_HEIGHT}
              />
            </Grid>
            <Grid item md={6} xs={12} container>
              <Box sx={{ paddingTop: 7 }}>
                <Typography
                  variant={"h5"}
                  data-testid={"my_description"}
                  sx={{
                    textAlign: {
                      md: "initial",
                      xs: "center",
                    },
                  }}
                >
                  {myDescription.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Section>
  );
}
