import React from "react";

import { FaAws, FaNodeJs, FaPhp, FaReact, FaDocker } from "react-icons/fa";
import { GrGraphQl, GrMysql } from "react-icons/gr";
import { DiMongodb, DiPostgresql, DiGoogleCloudPlatform } from "react-icons/di";
import { SiFastify, SiNestjs, SiFirebase, SiExpress } from "react-icons/si";

export interface StackProps {
  name: string;
  icon?: React.ReactNode;
  description?: string;
}

const STACK_ICON_SIZE = 100;
const STACK_COLORS = {
  react: "#00d8ff",
  vuejs: "#42b883",
  nodejs: "#215732",
  graphql: "#de33a6",
  amazon: "#ff9900",
  mysql: "#00758f",
  mongodb: "#589636",
  postgresql: "#31648d",
  php: "#8892be",
  google: "#4285f4",
  fastify: "#f8dc3c",
  nestjs: "#d4214a",
  firebase: "#ffcc2f",
  docker: "#248fe0",
};

export const Stacks: StackProps[] = [
  {
    name: "React & React Native",
    icon: <FaReact fontSize={STACK_ICON_SIZE} color={STACK_COLORS.react} />,
  },
  {
    name: "NodeJS",
    icon: <FaNodeJs fontSize={STACK_ICON_SIZE} color={STACK_COLORS.nodejs} />,
  },

  {
    name: "GraphQL",
    icon: <GrGraphQl fontSize={STACK_ICON_SIZE} color={STACK_COLORS.graphql} />,
  },
  {
    name: "AWS",
    icon: <FaAws fontSize={STACK_ICON_SIZE} color={STACK_COLORS.amazon} />,
  },

  {
    name: "MySQL",
    icon: <GrMysql fontSize={STACK_ICON_SIZE} color={STACK_COLORS.mysql} />,
  },
  {
    name: "MongoDb",
    icon: <DiMongodb fontSize={STACK_ICON_SIZE} color={STACK_COLORS.mongodb} />,
  },
  {
    name: "PostgresQL",
    icon: (
      <DiPostgresql
        fontSize={STACK_ICON_SIZE}
        color={STACK_COLORS.postgresql}
      />
    ),
  },
  {
    name: "PHP",
    icon: <FaPhp fontSize={STACK_ICON_SIZE} color={STACK_COLORS.php} />,
  },
  {
    name: "GCP",
    icon: (
      <DiGoogleCloudPlatform
        fontSize={STACK_ICON_SIZE}
        color={STACK_COLORS.google}
      />
    ),
  },

  {
    name: "Fastify",
    icon: <SiFastify fontSize={STACK_ICON_SIZE} color={STACK_COLORS.google} />,
  },
  {
    name: "NestJs",
    icon: <SiNestjs color={STACK_COLORS.nestjs} fontSize={STACK_ICON_SIZE} />,
  },
  {
    name: "SiFirebase",
    icon: (
      <SiFirebase
        color={STACK_COLORS.firebase}
        fontSize={STACK_ICON_SIZE - 3}
      />
    ),
  },
  {
    name: "Docker",
    icon: (
      <FaDocker color={STACK_COLORS.docker} fontSize={STACK_ICON_SIZE - 3} />
    ),
  },
  {
    name: "Express",
    icon: <SiExpress fontSize={STACK_ICON_SIZE - 3} />,
  },
];
