import React from "react";

export function ProfilePicture({
  profilePicturePath,
  height,
}: {
  profilePicturePath: string;
  height: number;
}) {
  return (
    <img
      src={profilePicturePath}
      style={{
        height: height,
        borderRadius: height / 2,
      }}
      alt={"Kennedy Profile"}
      data-testid={"profile_picture"}
    />
  );
}
