import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomeScreen } from "../screens";
import { AllMyLinksScreen } from "../screens/all-my-links";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/all-my-links",
    element: <AllMyLinksScreen />,
  },
]);

export function NavigationRoutes() {
  return <RouterProvider router={routes} />;
}
