import React from "react";

import { FleuryIcon, GoHealthIcon, SantaJoanaIcon } from "../../assets/icons";

interface CostumersListProps {
  name: string;
  icon: React.ReactNode;
}

export const CostumersList: CostumersListProps[] = [
  {
    name: "Nestlé",
    icon: (
      <img
        src="images/clients/nestle-nin/logo.png"
        alt={"nestle logo"}
        style={{ width: 160 }}
      />
    ),
  },
  {
    name: "Hospital Israelita Albert Einstein",
    icon: (
      <img
        src={"images/clients/einstein/logo.svg"}
        alt={"Logo Sociedade Beneficente Israelita Albert Einstein"}
        style={{ width: 250 }}
      />
    ),
  },
  {
    name: "Takaoka",
    icon: (
      <img
        src="images/clients/takaoka/logo.png"
        alt={"takaoka logo"}
        style={{ width: 160 }}
      />
    ),
  },
  {
    name: "Fleury",
    icon: <FleuryIcon fontSize={"large"} sx={{ fontSize: 160 }} />,
  },
  {
    name: "Materdei",
    icon: (
      <img
        src={"images/clients/materdei/logo.png"}
        alt={"materdei logo"}
        style={{ width: 160 }}
      />
    ),
  },
  {
    name: "Santa Joana",
    icon: <SantaJoanaIcon fontSize={"large"} sx={{ fontSize: 160 }} />,
  },
  {
    name: "GoHealth",
    icon: <GoHealthIcon fontSize={"large"} sx={{ fontSize: 160 }} />,
  },
];
